import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

function Video(props, ref) {
  const { size, src } = props;

  const videoRef = useRef();
  const [ isPlay, setIsPlay ] = useState(false);
  
  const poster = src + (src.indexOf('?') > -1? '&' : '?') + 'x-oss-process=video/snapshot,t_1,f_jpg,w_0,h_0,m_fast';

  function play() {
    videoRef.current.play();
  }

  function pause() {
    videoRef.current.pause();
  }

  function setPlayState() {
    setIsPlay(!isPlay);
  }

  useImperativeHandle(ref, () => ({
    play,
    pause
  }))

  return (
    <div className='video-container'>

      {!isPlay && (
        <div className='cover'>
          <div className='size-tip' onClick={play}>
            <i className='play-icon'></i>
            {/*{!size ? (*/}
            {/*  <>播放</>*/}
            {/*) : (*/}
            {/*  <>*/}
            {/*    使用流量播放*/}
            {/*    <span className='size'>{size}M</span>*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        onPause={setPlayState}
        onPlay={setPlayState}
        controls={isPlay}
        src={src}
        poster={poster}
        webkit-playsinline='true'
        playsInline
        controlsList='nodownload'
      ></video>
    </div>
  )
}

export default forwardRef(Video);