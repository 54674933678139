import React from 'react';
import ReactDOM from 'react-dom';
import Routers from './routers';
import reportWebVitals from './reportWebVitals';
import './asset/css/index.scss';
import './common/events.on';
import { Toast } from 'antd-mobile';

// 设置全局的 Toast 时间 和 禁止背景点击
Toast.config({
  duration: 3000,
  maskClickable: false
});

// 隐藏微信分享栏目
// document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
//   window.WeixinJSBridge.call('hideOptionMenu');
// });

var useragent = navigator.userAgent;
if (useragent.match(/WindowsWechat/) == 'WindowsWechat' || useragent.match(/MicroMessenger/i) != 'MicroMessenger') {
  alert('已禁止本次访问：您必须使用微信内置浏览器访问本页面！');
  window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=888"
}

ReactDOM.render(
  <React.StrictMode>
    <Routers />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
