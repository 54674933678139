import './index.scss';

const NotFound = () => {
  return (
    <div className="not-found-page">
      404 Not Found
    </div>
  )
}

export default NotFound;