import axios from 'axios';
import EventEmitter, { EVENTS_ENUM } from './events';
import localStorage, { STORAGE_ENUM } from './local-storage';

const instance = axios.create({
  baseURL: 'https://test.juwangyin.cn',
  timeout: 10000,
  transformResponse: (responseData) => {
    return typeof responseData === 'string'? JSON.parse(responseData || '{}') : responseData;
  }
})

instance.interceptors.request.use((config) => {
  const token = localStorage.get(STORAGE_ENUM.TOKEN);
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  if (config['loading']) {
    EventEmitter.emit(EVENTS_ENUM.LOADING, true);
  }

  return config;
})

//响应拦截
instance.interceptors.response.use(function (response) {
  const { status, data, statusText, config = {} } = response;

  if (config['loading']) {
    EventEmitter.emit(EVENTS_ENUM.LOADING, false);
  }


  return {
    code: status,
    data,
    msg: statusText,
  }
}, function (error) {
  // console.log('%c--error--', 'font-size: 20px; color: red;', error);
  // console.log(error.response);
  // console.log(error.config);

  if (error.response && error.response.data && error.response.data.error) {
    let { statusCode, message } = error.response.data.error;
    const { config = {} } = error.response;

    if (config['loading']) {
      EventEmitter.emit(EVENTS_ENUM.LOADING, false);
    }

    if (statusCode === 401) {
      localStorage.remove(STORAGE_ENUM.TOKEN);
      message = '请登录';
    }

    return Promise.resolve({
      code: statusCode,
      data: null,
      msg: message,
    })
  }

  if (error && error.config['loading']) {
    EventEmitter.emit(EVENTS_ENUM.LOADING, false);
  }

  return Promise.reject({
    code: 10000002, // 前端自定义的错误码
    data: null,
    msg: '网络错误，请刷新后重试'
  });
});


const request = {
  get: (url, params, option) => {
    return instance.get(url, Object.assign({}, option, { params }));
  },
  post: (url, params, option) => {
    return instance.post(url, params, option);
  },
  delete: (url, params, option) => {
    return instance.delete(url, Object.assign({}, option, { params }));
  }
}

export default request;