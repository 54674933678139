import { Routes, Route, HashRouter } from "react-router-dom";
import Login from "./page/login";
import List from "./page/list";
import Guide from './page/guide';
import Book from './page/book';
import Bind from "./page/bind";
import NotFound from "./page/not-found";
import BookContent from "./page/book-content";



/**
 * 如果想要把路由信息独立出来，可以使用 useRoutes 来独立
 */

/**
 * 路由
 * @returns 
 */
function Routers() {
  return (
    <HashRouter>
      <Routes>
        {/* 登录 */}
        <Route path="/" element={<Login />}></Route>
        <Route path="/login.html" element={<Login />}></Route>
        {/* 旧版资源内容列表 */}
        <Route path="/book-contents/:code/list.html" element={<List />}></Route>
        {/* 引导页面 */}
        <Route path="/guide.html" element={<Guide />}></Route>
        {/* 绑定书本 */}
        <Route path="/books/:code/bind.html" element={<Bind />}></Route>
        {/* 书本目录 */}
        <Route path="/book/:code/catalogue.html" element={<Book />}></Route>
         {/* 新版资源内容列表 */}
        <Route path="/book/:code/contents.html" element={<BookContent />}></Route>
        {/* 404 */}
        <Route path="/404.html" element={<NotFound />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default Routers;
