import request from './http';

/**
 * 发送验证码
 * @param {strng} mobile 
 * @returns 
 */
export function sendSmsCode(mobile) {
  return request.get('/verifications/send', { mobile }, { loading: true })
}

/**
 * 验证验证码
 * @param {strng} mobile 
 * @returns 
 */
export function verifySmsCode({ code, mobile } = {}) {
  return request.get('/verifications/verify', { mobile, code })
}

export function login({ code, mobile } = {}) {
  return request.post('/accounts/login', { mobile, code }, { loading: true })
}

// 微信登录绑定手机号
export function wxBind({ openId, mobile, code } = {}) {
  return request.post('/accounts/bind/wechat', { mobile, openId, code }, { loading: true })
}

/**
 * 绑定书籍
 * @param {string} code 
 * @returns 
 */
export function bindBook(code) {
  return request.post(`/books/${code}/bind`, {}, { loading: true });
}

/**
 * 通过code获取书本内容
 * @param {string} code 
 * @returns 
 */
export function getBookContent(code) {
  return request.get(`/book-contents/me/${code}`, {}, { loading: true });
}

/**
 * 通过code获取书本列表
 * @param {string} code 
 * @returns 
 */
export function getBookContentLit(code) {
  return request.get(`/book-contents/me/${code}/list`, {}, { loading: true });
}

/**
 * 获取书信息
 * @param {string} code 
 * @returns 
 */
export function getBooks(code) {
  return request.post(`/books/${code}/public`, {}, { loading: true });
}

// 获取风格设置
export function getStyle(code) {
  return request.get(`/style-settings/${code}`, {}, { loading: true });
}
