import { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { sendSmsCode, login, wxBind } from '../../common/api';
import { Toast } from 'antd-mobile';
import localStorage, { STORAGE_ENUM } from '../../common/local-storage';
import { useInterval, useDebounceFn } from 'ahooks';
import { useSearchParams } from 'react-router-dom';

const Login = () => {
  const [mobile, setMobile] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [interval, setInterval] = useState();
  const [second, setSecond] = useState(60);
  const [searchParams] = useSearchParams();

  const navigateTo = useNavigate();
  const { run: sendSmsCodeFn } = useDebounceFn(handleSendSmsCode, { leading: true, await: 2000 });
  const { run: loginFunc } = useDebounceFn(handleLogin, { leading: true, await: 2000 });
  const openId = searchParams.get('openId');
  // 清除失效的token
  localStorage.remove('TOKEN')

  useInterval(() => {
    if (second <= 0) {
      clearInterval();
    } else {
      setSecond((second) => second - 1);
    }
  }, interval);

  useEffect(() => {
    document.title = openId ? '绑定手机号' : '登录';
  }, [])

  function handleSendSmsCode() {
    if (!verifyMobile(mobile)) {
      Toast.show({
        content: mobile ? '手机号码格式错误' : '请输入手机号码',
      })
      return;
    }

    sendSmsCode(mobile).then((data) => {
      if (data.code === 200) {
        setInterval(1000);
      }

      Toast.show({
        content: data.code === 200 ? '验证码已发送' : data.msg,
      })
    }).catch((err) => {
      Toast.show({
        content: err.msg,
      })
    })
  }

  function clearInterval() {
    setInterval();
    setSecond(60);
  }

  function verifyMobile(mobile) {
    // 1开头的11位数字
    return /^1\d{10}$/.test(mobile)
  }

  function onChangeMobile(event) {
    const { value } = event.target;
    // 只能输入数字
    setMobile(value.replace(/\D/, ''));
  }

  function onChangeSmsCode(event) {
    const { value } = event.target;
    setSmsCode(value);
  }

  function handleLogin() {
    if (!verifyMobile(mobile)) {
      Toast.show({
        content: mobile ? '手机号码格式错误' : '请输入手机号码',
      })
      return;
    }

    if (!smsCode) {
      Toast.show({
        content: '请填写验证码',
      })
      return;
    }

    (openId ? wxBind({ mobile, code: smsCode, openId }) : login({ mobile, code: smsCode })).then((data) => {
      if (data.code === 200) {
      
        if (data.data && data.data.token) { // 登录成功
          localStorage.set(STORAGE_ENUM.TOKEN, data.data.token);
          console.log('登录成功',navigator)
          navigateTo(-1) //登录完成返回上一页
        }
        Toast.show({
          content: data.data ? '登录成功' : '验证码错误',
          duration: 1000
        });
      } else {
        Toast.show({
          content: data.msg || '请填写完整',
        })
      }
    }).catch((err) => {
      Toast.show({
        content: err.msg,
      })
    })
  }

  return (
    <div className='login-page'>
      <div className='title'>请登录</div>

      <div className='mobile-box'>
        <div className='before'>+86</div>
        <input className='mobile'
          onChange={onChangeMobile}
          value={mobile}
          placeholder='请输入手机号码进行登录'
          maxLength={11}
          type="text"
        />
      </div>

      <div className='smscode-box'>
        <input type="text"
          className='smscode'
          maxLength={6}
          value={smsCode}
          onChange={onChangeSmsCode}
          placeholder='请输入验证码'
        />

        {second === 60 ? (
          <button className='smscode-button' onClick={sendSmsCodeFn}>获取验证码</button>
        ) : (
          <button className='smscode-button disabled'>已发送 ({second}“)</button>
        )}
      </div>

      <button className='login-btn' onClick={loginFunc}>{openId ? '绑定手机号' : '登陆'}</button>
    </div>
  )
}

export default Login;
