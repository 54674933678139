import Events, { EVENTS_ENUM } from './events';
import { Toast } from 'antd-mobile';

/**
 *  监听做出操作
 */

let loadingCount = 0;
let loadingRef = 0;

Events.on(EVENTS_ENUM.LOADING, (show, content = '') => {
  loadingCount = show? loadingCount + 1 : loadingCount - 1 || 0;

  if (loadingCount === 1 && show) {
    loadingRef = Toast.show({
      icon: 'loading',
      content,
      duration: 0, // 为0则不会关闭
    });
  }

  if (loadingRef && loadingCount <= 0) {
    loadingCount = 0;
    loadingRef.close();
  }
});
