import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

function Audio(props, ref) {
    const { src, styleSetting } = props
    const audioRef = useRef();
    const [isPlay, setIsPlay] = useState(false);
    function play() {
        audioRef.current.play();
    }

    function pause() {
        audioRef.current.pause();
    }

    function setPlayState() {
        setIsPlay(!isPlay);
    }
    useImperativeHandle(ref, () => ({
        play,
        pause
    }))
    return (
        <div className='audio-box'>
            {!isPlay &&
                <div className='play-btn' style={{
                    backgroundImage:
                        `url('${styleSetting.playIcon
                        ||
                        require('./image/video-play.png')}')`
                }} onClick={play}></div>}
            {isPlay && <div className='pause-btn' style={{
                backgroundImage:
                    `url('${styleSetting.pauseIcon
                    ||
                    require('./image/video-pause.png')}')`
            }} onClick={pause}></div>}
            <div className='play-btn-box'></div>
            <audio
                onPause={setPlayState}
                onPlay={setPlayState}
                ref={audioRef}
                controls
                preload='auto'
                src={src}
            ></audio>
        </div>
    )
}
export default forwardRef(Audio);