import Cookies from 'universal-cookie';
export const cookies = new Cookies();

class LocalStorage {

	set(key, value) {
		if (!key || !value) return;
		window.localStorage.setItem(key, value);
	}

	get(key) {
		if (!key) return;
		return window.localStorage.getItem(key) || cookies.get(key) || '';
	}

	remove(key) {
		if (!key) return;
		window.localStorage.removeItem(key);
	}

	clear() {
		window.localStorage.clear();
	}
}

export default new LocalStorage();

 

export const STORAGE_ENUM = {
	TOKEN: 'TOKEN',
}