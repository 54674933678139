import { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import { useParams, useNavigate } from 'react-router-dom';
import { bindBook } from '../../common/api';
import './index.scss';
import localStorage, { cookies } from '../../common/local-storage';


const Bind = () => {
  const [isBindErr, setIsBindErr] = useState(false);
  const { code } = useParams();
  const [tips, setTips] = useState('网络错误请稍后再试');
  const navigateTo = useNavigate();
  const accessToken = cookies.get('accessToken') || localStorage.get('TOKEN')
  localStorage.set('TOKEN', accessToken)


  useEffect(() => {
    document.title = '绑定书籍';
    if (accessToken) {
      bindBook(code).then((data) => {
        if (data.code === 200) {
          if (data.data.showVersion === 1) {
            navigateTo(`/book/${code}/catalogue.html`);
          } else {
            navigateTo(`/guide.html?code=${code}`);
          }
        } else if (data.code === 401) {
          navigateTo(`/login.html`)
        } else if (data.code === 404) {
          navigateTo('/404.html')
        } else {
          pageTipsShow(data.msg);
        }
      }).catch((err) => {
        pageTipsShow(err.msg);
      });
    } else {
      navigateTo(`/login.html?openId=${cookies.get('openId')}`)
    }

  }, []);

  function pageTipsShow(msg) {
    setTips(msg);
    Toast.show({
      content: msg,
      afterClose: () => {
        setIsBindErr(true);
      }
    });
  }

  return (
    <div className='bind-page'>
      {isBindErr && (
        <p className='no-data'>{tips}</p>
      )}
    </div>
  );
}

export default Bind;