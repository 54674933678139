import { useEffect, useState } from 'react';
import './index.scss';
import { Toast } from 'antd-mobile';
import { getBooks } from '../../common/api';
import { useSearchParams } from 'react-router-dom';

const Guide = () => {
  const [searchParams] = useSearchParams();
  const [coverImage, setCoverImage] = useState(require('./image/book-icon.png'));


  useEffect(() => {
    document.title = '指引';
    Toast.show({
      content: '请打开手机上的扫一扫',
    });

    getBooksCover();
  }, []);

  // 获取书本封面
  function getBooksCover() {
    const code = searchParams.get('code');
    if (code) {
      getBooks(code).then(data => {
        if (data.code === 200 && data.data?.image) {
          setCoverImage(data.data.image);
        }
      })
    }

  }

  return (
    <div className="guide-page">
      <p className='title'>您已成功登录</p>
      <p className='sub-title'>可扫描书本上的二维码浏览资源</p>
      <img src={coverImage} className="book-icon" alt="封面.jpg" />
    </div>
  )
}

export default Guide;