import { useEffect, useState } from 'react';
import './index.scss';
import { getBookContentLit, getStyle } from '../../common/api';
import { useParams, useNavigate } from 'react-router-dom';
import localStorage from "../../common/local-storage";
import {SearchBar, Toast} from "antd-mobile";


const Book = () => {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [title, settitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [cover, setCover] = useState('');
  const [styleSetting, setStyleSetting] = useState({});
  const [loading, setLoading] = useState(true)
  const { code } = useParams();
  const navigateTo = useNavigate();
  const accessToken = localStorage.get('TOKEN')
  // 触发微信授权跳转
  if (!accessToken) {
    window.location.href = `${window.location.origin}/b/${code}`;
  }

  useEffect(() => {
    getBook()
  }, []);

  // 获取书本封面
  function getBook() {
    return getBookContentLit(code).then(data => {
      if (data.code === 200 && data.data && data.data.length > 0) {
        const bookTitle = data.data[0].book.name;
        const bookSubTitle = data.data[0].book.title;
        const bookCover = data.data[0].book.image;
        setList(data.data);
        setAllList(data.data);
        settitle(bookTitle);
        setSubTitle(bookSubTitle);
        setCover(bookCover);

        document.title = bookSubTitle ? bookTitle + ' - ' + bookSubTitle : bookTitle;
        // 如果styleSettingId为null就默认风格
        if (data.data[0].book.styleSettingId) {
          _getStyle(data.data[0].book.styleSettingId)
        }
      } else if (data.code === 401) {
        localStorage.remove('TOKEN')
        navigateTo(`/login.html?code=${code}`)
      } else {
        Toast.show({
          content: data.msg,
        })
      }
    }).then(() => {
      setTimeout(() => {
        setLoading(false)
      }, 100);
    })

  }

  function _getStyle(styleSettingId) {
    getStyle(styleSettingId).then((data) => {
      console.log(data.data)
      if (data.code === 200 && data.data) {
        setStyleSetting(data.data.value)
      }
    })
  }

  function searchChange(val) {
    const nowList = allList.filter(item => item.name.indexOf(val) !== -1);
    setList(nowList);
  }

  if (loading) return <div></div>;

  return (
    <div className="book-page"
      style={{
        backgroundImage:
          `url('${styleSetting.bookBackground
          ||
          require('./image/book-bg.jpg')}')`
      }}>
      <div style={{ 'margin-bottom': '5px' }}>
        <SearchBar
          // ref={searchRef}
          placeholder='请输入内容'
          showCancelButton
          onChange={searchChange}
        />
      </div>
      {/* 这个是设置收藏头像图的  不是背景 */}
      <img src={cover || require('./image/favorites.png')} className='collection-image'></img>
      <div className="title"
        style={{
          backgroundImage:
            `url('${styleSetting.bookTitleBackground
            ||
            require('./image/title-bg.png')}')`,
          color: styleSetting.fontColor ? `#${styleSetting.fontColor}` : '#ffffff'
        }}>
        {subTitle ?
          <div className='sub-title-content'>《{title}》<p className='sub-title-p'>{subTitle}</p></div>
          : <div className='title-content'>《{title}》</div>}
      </div>
      <div className="list-box">
        {list.map((item, index) => {
          return <div key={index}
            style={{
              backgroundImage:
                `url('${styleSetting.bookNameBackground
                ||
                require('./image/item-bg.png')}')`,
              color: styleSetting.fontColor ? `#${styleSetting.fontColor}` : '#ffffff'
            }}
            onClick={() => {
              navigateTo(`/book/${item.id}/contents.html`);
            }} className="item">{item.name}</div>
        })}
      </div>
    </div>
  )


}

export default Book;